<template>
  <div>
    <vs-table search stripe border description :sst="true" :data="tableData" :max-items="table.length"
      :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              ">
            <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button size="small" color="success" type="border" @click="handleExport('excel')">Excel</vs-button>
        </div>
      </template>
      <template slot="thead">
        <!-- <vs-th></vs-th> -->
        <vs-th sort-key="branch_code">Branch Code</vs-th>
        <vs-th sort-key="branch_name">Branch Name</vs-th>
        <vs-th sort-key="principal_code">Principal Code</vs-th>
        <vs-th sort-key="principal_name">Principal Name</vs-th>
        <vs-th sort-key="gr_number">Gr Number</vs-th>
        <vs-th sort-key="gr_doc_date">Gr Doc Date</vs-th>
        <vs-th sort-key="po_doc_date">PO Doc Date</vs-th>
        <vs-th sort-key="po_number">PO Number</vs-th>
        <vs-th sort-key="sub_number">Sub Number</vs-th>
        <vs-th sort-key="sub_po_number">Sub PO Number</vs-th>
        <vs-th sort-key="faktur">Faktur</vs-th>
        <vs-th sort-key="po_amount">PO Amount</vs-th>
        <vs-th sort-key="gr_doc_status">GR Doc Status</vs-th>
        <vs-th sort-key="gr_amount">GR Amount</vs-th>
        <vs-th sort-key="vendor_bill_no">Vendor Bill No</vs-th>
        <vs-th sort-key="bill_doc_status">Bill Doc Status</vs-th>
        <vs-th sort-key="vendor_bill_amount">Vendor Bill Amount</vs-th>
        <vs-th sort-key="vendor_payment_no">Vendor Payment No</vs-th>
        <vs-th sort-key="pay_doc_status">Pay Doc Status</vs-th>
        <vs-th sort-key="vendor_pay_amount">Vendor Pay Amount</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <!-- <vs-td> </vs-td> -->
          <vs-td :data="data[indextr].branch_code">
            {{ data[indextr].branch_code }}
          </vs-td>
          <vs-td :data="data[indextr].branch_name">
            {{ data[indextr].branch_name }}
          </vs-td>
          <vs-td :data="data[indextr].principal_code">
            {{ data[indextr].principal_code }}
          </vs-td>
          <vs-td :data="data[indextr].principal_name">
            {{ data[indextr].principal_name }}
          </vs-td>
          <vs-td :data="data[indextr].gr_number">
            {{ data[indextr].gr_number }}
          </vs-td>
          <vs-td :data="data[indextr].gr_doc_date">
            {{ dateFormat(data[indextr].gr_doc_date) }}
          </vs-td>
          <vs-td :data="data[indextr].po_doc_date">
            {{ dateFormat(data[indextr].po_doc_date) }}
          </vs-td>
          <vs-td :data="data[indextr].po_number">
            {{ data[indextr].po_number }}
          </vs-td>
          <vs-td :data="data[indextr].sub_number">
            {{ data[indextr].sub_number }}
          </vs-td>
          <vs-td :data="data[indextr].sub_po_number">
            {{ formatPrice(data[indextr].sub_po_number) }}
          </vs-td>
          <vs-td :data="data[indextr].faktur">
            {{ formatPrice(data[indextr].faktur) }}
          </vs-td>
          <vs-td :data="data[indextr].po_amount">
            {{ formatPrice(data[indextr].po_amount) }}
          </vs-td>
          <vs-td :data="data[indextr].gr_doc_status">
            {{ data[indextr].gr_doc_status }}
          </vs-td>
          <vs-td :data="data[indextr].gr_amount">
            {{ data[indextr].gr_amount }}
          </vs-td>
          <vs-td :data="data[indextr].vendor_bill_no">
            {{ data[indextr].vendor_bill_no }}
          </vs-td>
          <vs-td :data="data[indextr].bill_doc_status">
            {{ data[indextr].bill_doc_status }}
          </vs-td>
          <vs-td :data="data[indextr].vendor_bill_amount">
            {{ data[indextr].vendor_bill_amount }}
          </vs-td>
          <vs-td :data="data[indextr].vendor_payment_no">
            {{ data[indextr].vendor_payment_no }}
          </vs-td>
          <vs-td :data="data[indextr].pay_doc_status">
            {{ data[indextr].pay_doc_status }}
          </vs-td>
          <vs-td :data="data[indextr].vendor_pay_amount">
            {{ data[indextr].vendor_pay_amount }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination style="padding-top: 5px" :total="total" v-model="setPage" />
  </div>
</template>
<script>
import moment from "moment";
import { mapState, mapActions } from "vuex/dist/vuex.common.js";
export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    startPostingDate: {
      type: Date,
    },
    endPostingDate: {
      type: Date,
    },
    startDocDate: {
      type: Date,
    },
    endDocDate: {
      type: Date,
    },
    startDueDate: {
      type: Date,
    },
    endDueDate: {
      type: Date,
    },
    openKeyDate: {
      type: Date,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({ getP2PFlowReport: 'reportP2PFlow/getP2PFlowReport' }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.getP2PFlowReport({
          length: this.table.length,
          page: this.table.page,
          search: this.table.search == '' ? undefined : this.table.search,
          order: this.table.order,
          sort: this.table.sort,
        }).then(() => {
          this.$vs.loading.close();
        });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    handleExport(file) {
      this.$vs.loading();
      var fileTitle =
        "ARTRANS_REPORT_" +
        (this.territoryNames.length > 0
          ? this.territoryNames.join("-")
          : "all") +
        "_";

      if (this.startDocDate || this.endDocDate) {
        fileTitle +=
          "DOC_DATE_" +
          moment(this.startDocDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endDocDate).format("YYYY-MM-DD") +
          "_";
      }

      if (this.startPostingDate || this.endPostingDate) {
        "POSTING_DATE_" +
          moment(this.startPostingDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endPostingDate).format("YYYY-MM-DD") +
          "_";
      }

      if (this.startDueDate || this.endDueDate) {
        "DUE_DATE_" +
          moment(this.startDueDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endDueDate).format("YYYY-MM-DD") +
          "_";
      }

      fileTitle +=
        "OPEN_KEY_DATE_" + moment(this.openKeyDate).format("YYYY-MM-DD");

      this.$http
        .get(this.baseUrl + "/export", {
          params: {
            territory_ids: this.territoryIDs,
            start_posting_date: this.startPostingDate
              ? moment(this.startPostingDate).format("YYYY-MM-DD")
              : null,
            end_posting_date: this.endPostingDate
              ? moment(this.endPostingDate).format("YYYY-MM-DD")
              : null,
            start_doc_date: this.startDocDate
              ? moment(this.startDocDate).format("YYYY-MM-DD")
              : null,
            end_doc_date: this.endDocDate
              ? moment(this.endDocDate).format("YYYY-MM-DD")
              : null,
            start_due_date: this.startDueDate
              ? moment(this.startDueDate).format("YYYY-MM-DD")
              : null,
            end_due_date: this.endDueDate
              ? moment(this.endDueDate).format("YYYY-MM-DD")
              : null,
            open_key_date: this.openKeyDate
              ? moment(this.openKeyDate).format("YYYY-MM-DD")
              : null,
            file: file,
            title: fileTitle,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "success",
              title: "Processing",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    draw() {
      this.getData();
    },
  },
  computed: {
    ...mapState({
      tableData: (state) => state.reportP2PFlow.tableData,
      total: (state) => state.reportP2PFlow.total,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>